<template>
    <div class="form-access">
        <el-form
            @submit.native.prevent
            ref="passwordResetForm"
            :model="passwordResetForm"
            :rules="passwordResetFormRules" >
            <div class="mb-3">
                <img class="img-fluid" src="./../../assets/img/infibo-logo.svg" alt="inFibo Logo">
            </div>
            <p class="text-muted text-center mb-3">{{ $t('resetPassword') }}</p>
            <el-form-item prop="email">
                <el-input
                    clearable
                    v-model="passwordResetForm.email"
                    :disabled="profileLoadingState"
                    :placeholder="$t('enterEmail')"
                    @keyup.enter.native="submitPasswordResetForm"/>
            </el-form-item>
            <el-form-item>
                <el-button
                    class="w-100"
                    type="primary"
                    @click="submitPasswordResetForm"
                    :disabled="profileLoadingState"
                    :loading="profileLoadingState">{{ $t('reset') }}
                </el-button>
            </el-form-item>
        </el-form>
        <div class="form-group text-center">
            <router-link class="text-muted text-decoration-none" to="/login">{{
                    $t('goToLogin')
                }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "PasswordReset",
    i18n: {
        messages: {
            en: {
                resetPassword: 'Reset password',
                reset: 'Reset',
                goToLogin: 'Go to login',
                enterEmail: 'Enter email',
                wrongEmail: 'Invalid Email entered',

                linkHasBeenSent: 'A link to reset your password has been sent to your email.',
                responseErrors: {
                    unknownErrorTitle: 'Unknown error',
                    unknownErrorMessage: 'Contact support',

                    networkErrorTitle: 'Network error',
                    networkErrorMessage: 'Check your connection or try again later',

                    wrongEmailTitle: 'User not found',
                    wrongEmailMessage: 'Check the entered data and try again',

                    serverErrorTitle: 'Server error',
                    serverErrorMessage: 'Contact support',
                }
            },
            ru: {
                resetPassword: 'Сброс пароля',
                reset: 'Сбросить',
                goToLogin: 'Перейти ко входу',
                enterEmail: 'Введите email',
                wrongEmail: 'Введен неверный Email',

                linkHasBeenSent: 'Ссылка для сброса пароля была отправлена на ваш email.',
                responseErrors: {
                    unknownErrorTitle: 'Неизвестная ошибка',
                    unknownErrorMessage: 'Свяжитесь со службой поддержки',

                    networkErrorTitle: 'Ошибка сети',
                    networkErrorMessage: 'Проверьте подключение или повторите попытку позже',

                    wrongEmailTitle: 'Пользователь не найден',
                    wrongEmailMessage: 'Проверте введенные данные и повторите попытку',

                    serverErrorTitle: 'Ошибка сервера',
                    serverErrorMessage: 'Свяжитесь со службой поддержки',
                }
            }
        }
    },
    data: function () {
        return {
            passwordResetFormRules: {
                email: [
                    {
                        required: true,
                        message: () => this.$t('enterEmail'),
                    },
                    {
                        type: 'email',
                        message: () => this.$t('wrongEmail'),
                    }
                ],
            },
            passwordResetForm: {
                email: '',
            },
        }
    },
    computed: {
        profileLoadingState: function () {
            return this.$store.getters.PROFILE_LOADING_STATE
        }
    },
    methods: {
        submitPasswordResetForm: function () {
            this.$refs['passwordResetForm'].validate(valid => {
                if(!valid) {
                    return false
                }

                // Notification fields
                let title = ''
                let message = ''
                let type = 'success'

                // Submit password reset form
                this.$store.dispatch('PROFILE_SEND_RESET_PASSWORD_LINK', this.passwordResetForm)
                    .then(() => {
                        title = 'Ok'
                        message = this.$t('linkHasBeenSent')
                        this.$refs['passwordResetForm'].resetFields()
                    })
                    .catch(e => {
                        type = 'error'
                        if (!e.response) {
                            title = this.$t('responseErrors.networkErrorTitle')
                            message = this.$t('responseErrors.networkErrorMessage')
                        }

                        if (e.response && (e.response.status === 400 || e.response.status === 422)) {
                            title = this.$t('responseErrors.wrongEmailTitle')
                            message = this.$t('responseErrors.wrongEmailMessage')
                        }

                        if (e.response && e.response.status >= 500) {
                            title = this.$t('responseErrors.serverErrorTitle')
                            message = this.$t('responseErrors.serverErrorMessage')
                        }
                    })
                    .finally(() => {
                        this.$notify({
                            title,
                            message,
                            type,
                        })
                    })
            })
        }
    }
}
</script>

<style scoped>
.form-access {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 14px -6px;
    border-radius: 5px;
    padding: 30px;
    border: 1px solid #f2f4f9;
    max-width: 350px;
}
</style>
